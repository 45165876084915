@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities"; */



* {
    outline: 0 !important;
    padding: 0;
    margin: 0;
    outline: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
  }
  body {
    /* background: url('/img/pageBg.png') no-repeat center top #111827; */
    /* background-image: url(/img/pageBg.png); */
    background-repeat: no-repeat;
    background-size: 100% 60%;
    background-position: center top;
    font-family: 'Space Grotesk';
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Space Grotesk';
  }
  
  .custom-datepicker .MuiInput-underline:before{
      border-bottom: none
  }
  .custom-datepicker .MuiInput-underline input{
    border: none
  }

  .custom-datepicker .MuiInput-underline{
    box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
    border-radius: .375rem;
    border: 1px solid #cad1d7;
  }

  .custom-datepicker svg{
    width: 18px;
    height: auto;
    margin-right: 10px;
  }

  
.MuiInput-underline:before{
  border-bottom: none !important;
}

.MuiInput-underline:after{
  border-bottom: none !important;
}

.MuiTableCell-head, .MuiTableCell-root {
  padding: 0.2rem 0.5rem !important;
}

.MuiTableCell-head span{
  font-size: 0.75rem !important;
  
}

.MuiTypography-body1, .MuiTypography-body2{
  font-size: 0.85rem !important;
}

.MuiTableCell-root{
  border-bottom: 1px solid #ccc !important;
}

.MuiFilledInput-multiline {
  padding: 0 !important;
}

.MuiFilledInput-multiline textArea{
  padding: 15px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  padding: 0 !important;
  background-color: white;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  transition: box-shadow .15s ease;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiOutlinedInput-notchedOutline{
  border: 1px solid #cad1d7;
}

.MuiAutocomplete-popupIndicator svg{
  width: 24px;
  height: auto;
}

.MuiAlert-filledError{
  background-color: #DC2626 !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  width: 1rem !important;
}
.MuiFormHelperText-contained {
  margin-left: 0 !important;
}

@media print {
  .admin-header-main{
    min-height: auto !important;
  }
  .no-print, .no-print * {
      display: none !important;
  }

  .print-bg-black{
    background-color: #000 !important;
  }


  .print-exact-color {
    -webkit-print-color-adjust: exact;
  }}

