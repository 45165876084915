@media print {
    @page {
      /* size: 4in 4in landscape;
      margin: 100cm 0.1016cm 100cm 0.1016cm; */
      /* margin-top: 500cm;
      margin-bottom: 0.36in;
      margin-left: 0.04in;
      margin-right: 0.04in; */

      size: 4in 4in landscape;
      margin-top: 250cm;
      margin-left: 50cm;
      margin-bottom: 5cm;
    }
}  